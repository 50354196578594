"use client";

import React, { useState } from "react";
import Image from "next/image";
import clsx from "clsx";
import {
  ImageLoader,
  PlaceholderValue,
  StaticImport,
} from "next/dist/shared/lib/get-img-props";
import defaultAvatar from "~/img/default-avatar.png";

const sizes = {
  navbar: {
    className: "min-h-8 min-w-8 h-8 w-8",
    dimension: 28,
  },
  profile: {
    className: "min-h-32, min-w-32 h-32 w-32",
    dimension: 128,
  },
  profileItem: {
    className: "min-h-12, min-w-12 h-12 w-12",
    dimension: 48,
  },
} as const;

export type AvatarProps = {
  src?: string | StaticImport;
  size: keyof typeof sizes;
  className?: string;
  placeholder?: PlaceholderValue;
  loader?: ImageLoader;
  "data-sentry-mask"?: boolean;
  defaultImage?: string;
};

const Avatar: React.FC<AvatarProps> = ({
  src: srcProp = defaultAvatar,
  size,
  className,
  placeholder,
  loader,
  defaultImage = "/public/img/default-avatar.png",
  ...props
}) => {
  const [src] = useState(srcProp);

  return (
    <Image
      src={src || defaultImage}
      alt=""
      placeholder={placeholder}
      className={clsx(
        sizes[size].className,
        "inline-block rounded-full",
        className
      )}
      height={sizes[size].dimension}
      width={sizes[size].dimension}
      onError={(e) => {
        e.currentTarget.src = defaultImage;
      }}
      loader={loader}
      data-sentry-mask={props["data-sentry-mask"]}
    />
  );
};

export default Avatar;
